// Theme variables
$primary: #0095DB;
$secondary: #F8B002;
$primary-lighter: #DEF1FA;

//@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
//$font-family-sans-serif: 'PT Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
$font-family-base: 'Roboto', sans-serif;

//$black: #00323a !default;

//variables
$light: #E6F3FB;
$dark: #06262D;
$body-bg: #f3fbff;
$border-radius: 10px;

$primary-faded: lighten($primary, 25%);

$headings-color: $primary;
$headings-font-weight: 600;
$line-height-base: 1.65;
$account-button-style: 'secondary';

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1100px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1900px
);

@import '@/src/scss/preset/woerdensport.scss';

$owl-image-path: '~owl.carousel/src/img/';
@import "~owl.carousel/src/scss/owl.carousel";

body {
  color: $dark;
}

.bordered-rounded-img {
  border-radius: 0.6rem !important;
}

.flex-wrapper {
  display: flex;
  min-height: 70vh;
  flex-direction: column;
  justify-content: space-between
}

.wrapper {
  margin-bottom: -12rem;
}

.content-wrapper {
  padding: 1.5rem 2rem 12rem 2rem;
  @include media-breakpoint-down(md) {
    padding: 1.5rem 1rem 10rem 1rem;
  }
}

.main-headerless {
  @extend .pt-5;
}

.border-radius-top-1 {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.jobVacancy--title {
  @extend .d-none;
}

.border-radius-bottom-1 {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.content-news-detail-wrapper {
  img {
    width: 100%;
    height: auto;
  }
}

.banner--img {
  max-height: 400px;
  @extend .object-fit-cover
}

article {
  border-radius: 15px;

  .border-rad-bottom {
    border: 1px solid $dark;
    border-radius: 0 0 15px 15px;
  }

  .border-rad-top {
    border-radius: 15px 15px 0 0;
  }
}

.mobile-hamburger-color {
  @extend .text-primary;
}

.navbar {
  padding: 0px;

  .navbar-collapse {
    @include media-breakpoint-down(md) {
      background-color: $primary;
      li.nav-item, .nav-link {
        color: white;
      }
    }
  }

  li.nav-item {
    margin-bottom: 6px;

    a {
      padding: 16px 0;
    }

    &.active:after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 6px solid $primary;
      margin-top: -6px;
      position: relative;
      bottom: -6px;
      opacity: 1;
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }

    &:not(.active):hover:after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 6px solid $primary-faded;
      margin-top: -6px;
      position: relative;
      bottom: -6px;
      opacity: 1;
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }
  }
}

.owl-testimonials {
  .owl-stage-outer {
    height: 100%;

    .owl-stage {
      height: 100%;

      .owl-item {
        height: 100%;
      }
    }
  }
}

.card:not(.border-primary, .border-secondary) {
  border: none;
}

.hamburger--squeeze {
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    @extend .bg-primary;
  }
}

.bubble-flavor {
  width: 10%;
  position: absolute;
  right: 2rem;
  top: -1rem;
  @include media-breakpoint-down(md) {
    width: 15%;
  }
  @include media-breakpoint-down(sm) {
    width: 20%;
  }
}

.bubble-flavor--banner {
  width: 20%;
  max-width: 130px !important;
  position: absolute;
  right: -2rem;
  bottom: -1.5rem;
}

.bubble-flavor-light--banner {
  width: 20%;
  max-width: 130px !important;
  position: absolute;
  left: -1rem;
  top: -2rem;
}

.hero--img {
  max-height: 460px
}

.bg-header-bar {
  background: $primary;
}

.share-kop-wrapper p {
  font-weight: 600;
}

figure.img-cover {
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(md) {
    height: 170px;
  }
  @include media-breakpoint-down(sm) {
    height: 130px;
  }
}

th {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid $primary !important;
}

.rounded-img {
  border: none;
  border-radius: 1.25rem;
  margin-bottom: .5rem;
}

.hover--box-shadow {
  text-decoration: none !important;

  img {
    transition: all 0.3s;
  }

  &:hover {
    img {
      box-shadow: 5px 5px 15px 5px #0000000f;
    }
  }
}

//.flavor-shape {
//  @include media-breakpoint-down(md) {
//    height: 30px !important;
//  }
//}

.menu-block {
  border: 2px solid $white;
  border-radius: 15px;
  align-content: center;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  transition: all 0.3s;

  &:hover {
    background-color: $dark;
  }

  &.active {
    background-color: $dark;
  }

  a {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $white;
    width: 100px;
    height: 100px;

    i.fas {
      display: inline-block;
      width: 100%;
    }

    &:hover {
      text-decoration: none;
    }

  }
}

.space-logo {
  @include media-breakpoint-up(md) {
    margin-top: 7rem;
  }
}

@include media-breakpoint-up(md) {
  .position-md-static {
    position: static !important;
  }
}

//.planner-table {
//  @include media-breakpoint-down(md) {
//    th:last-child, td:last-child {
//      display: none !important;
//    }
//  }
//}

.word-break {
  word-break: break-word;
}

.border-thick {
  border-width: 4px !important;
}

.navbar-brand {
  display: block;
  position: absolute;
  left: 170px;
  top: 0;
  @include media-breakpoint-down(sm) {
    left: 0;
    margin: 0;
  }

  img {
    height: 9rem;
    position: absolute;
    top: -43px;
    left: -1rem;
    z-index: 999;
    max-width: none;
    @include media-breakpoint-down(xl) {
      height: 7.5rem;
      left: -4rem;
    }
    @include media-breakpoint-down(lg) {
      height: 7rem;
      left: -10rem;
    }
    @include media-breakpoint-down(md) {
      height: 6rem;
      top: -56px;
      z-index: 99;
    }
    @include media-breakpoint-down(sm) {
      left: auto;
      margin: 0;
    }
  }
}

.logo--box {
  height: 120px;
  width: 203px;
  border-radius: 0 0 101px 0;
}

.navbar-collapse .dropdown-menu {
  top: 100%;
}

.home h2#rooster-header {
  text-align: center;
}

#form-container {
  margin-left: inherit !important;
  margin-right: inherit !important;
}

.home {
  form#rooster-form div.form-group {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  #form-container {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  h2#rooster-header {
    color: $info;
    text-align: center;
  }
}

footer {
  .social-icons {
    margin: 0;
  }
}

#invoices div.card-header {
  background-color: $info;
  color: $white;
  border-radius: 0;
}

#invoices div.card-footer {
  background-color: $white;
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  border-radius: 0;
}


#invoices div.card-header span.js-resend-invoice {
  color: $white !important;
}

@include media-breakpoint-up(sm) {
  #side-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px;
    z-index: -1;
  }

  #side-menu-bg {
    height: 100vh;
    background-color: $info;
  }

  .navbar {
    z-index: 2;
  }

  #topNav {
    z-index: 2;
    li a {
      font-weight: 400;
    }
  }
}

#topNav li.active a {
  color: $info;
}

#footer-img {
  z-index: -2;
  position: relative;
}

.navbar-collapse .dropdown-menu {
  background-color: $primary-faded;
  color: $white;
  border-radius: 0px;
  padding: 0;
  border: none;

  a {
    padding: 16px 8px !important;
    color: $white;
  }
}

.account--button {
  @include media-breakpoint-down(md) {
    font-size: .8em;
    padding: 0.5em 0.8em;
  }
}

.account--button-wrapper {
  @extend .p-0;
}

.accordion {
  .card-header {
    background-color: $primary-lighter;
    border-color: $primary;
    button, button::after {
      @extend .text-dark;
    }
  }
}

.header-border {
  @include media-breakpoint-up(md) {
    border-bottom: solid 14px $primary;
  }
  border-color: $primary;
}

nav.navbar + #main-content {
  border-top: 8px solid $primary;
}

#main-content, main, .top-spacing {
  overflow-x: hidden;
  margin-top: -25px;
  border-color: $primary;
}

.btn-group .dropdown-menu {
  top:100%;
  margin-top: 10px !important;
  @include media-breakpoint-up(lg) {
    .dropdown-item {
      color: white;
    }
  }
}

.container-fluid-top-header {
  @include media-breakpoint-down(md) {
    background-color: $primary;
  }
}

.shoppingcart--icon {
  @extend .text-primary;
}

.d-none .d-sm-table-cell {
  @extend .border-0;
}

.banner--footer {
  border-top: 0;
  background: none;
}

.vacancy--summary, .vacancy-detail-component {
  th {
    @extend .border-0;
  }
  .btn {
    @extend .btn-secondary;
  }
  h4 {
    @extend .text-dark;
  }
}
.vacancy--summary {
  .card {
    border: 2px solid $secondary !important;
  }
}

.jobvacancy--listitem {
  @extend .text-primary;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: inherit !important;
  color: rgba(255, 255, 255, 0.75) !important;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background: white !important;
}

.dropdown-menu.sub-dropdown.show {
  background: #7fd3fb !important;
}

.hero-cta {
  background: radial-gradient(circle, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.2) 35%, rgba(0, 0, 0, 0) 100%);
  text-align: center !important;

  .cta-wrapper {
    bottom: 10%;
    @include media-breakpoint-down(md) {
      bottom: 30%;
    }

    .cta-wrapper--inner {
      width: 45%;
      @include media-breakpoint-down(md) {
        width: 90%;
        h2 {
          font-size: 17px;
        }
      }
      @include media-breakpoint-down(sm) {
        h1 {
          display: none;
        }
      }

      .hero-payoff {
        max-width: 400px;
      }
    }
  }
}

.footer-cta-banners {
  @include media-breakpoint-down(md) {
    display: none !important;
  }

  .footer-cta-item:hover {
    filter: contrast(0.5);
  }
}

.double--banner {
  @include media-breakpoint-up(md) {
    margin-top:-160px;
  }
}

.double--title {
  margin-top: -63px;
  @include media-breakpoint-down(md) {
    bottom: 0;
  }
}

#Rooster {
  .table tbody th, .table td {
    border-top: 1px solid $primary !important;
  }
}

.quick-menu-item {
  word-break: break-all;

  &:hover {
    filter: contrast(.5);
  }
}

.sidebar-nav {
  h1, h2, h3, h4, h5, h6 {
    color: $white;
  }
  color: $white;
}

.btn-login {
  background-color: $dark;
}

div#Rooster {
  padding-top: 0rem !important;
}

#footer-bg {
  background: url('../img/golf-donker2.png');
  height: 50px;
  background-size: 100% 50px;
  @include media-breakpoint-up(md) {
    background-size: 100% 75px;
    height: 75px;
  }
  @include media-breakpoint-up(lg) {
    background-size: 100% 100px;
    height: 100px;
  }
  @include media-breakpoint-up(xl) {
    background-size: 100% 100%;
    height: 180px;
  }
}

.quick-navigation--banner {
  @extend .rounded;
  @extend .border-secondary;
  @extend .border;
  @extend .py-2;
  @extend .px-3;
  @extend .mb-3;
}

footer a:hover {
  color: $white;
}

.review-avatar {
  object-fit: cover;
  width: 75px !important;
  height: 75px;
}

.full-round-banner {
  border-radius: 50rem;
  border: solid 4px;

  &:hover {
    img {
      transform: scale(0.96);
    }
  }

  img {
    border-radius: 50rem;
  }
}

.read-on {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.sidebar {
  min-width: 500px;
  max-width: 500px;
  background: $primary;
  transition: all .3s;
  position: relative;
  z-index: 1;
  min-height: 90vh;
  @include media-breakpoint-down(md) {
    min-width: 100%;
    min-height: unset;
  }

  .sidebar-nav {
    padding: 0 6rem;
    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }
  }
}

// Calendar
$space: 1.5rem;

.calendar {
  margin: auto (-$space);
  box-shadow: 0 ($space * .125) ($space * .25) rgba(0, 0, 0, .025);


  .owl-carousel:not(.owl-loaded) {
    //padding: 0 1.5rem;
    > .item:not(:first-child) {
      display: none;
    }
  }

  .owl-carousel .item {
    position: relative;
    z-index: 100;
    -webkit-backface-visibility: hidden;
  }

  li figure {
    padding-top: 0;
    height: $space * 10;
    border-radius: 12px;
    overflow: hidden;
    background-size: cover;

    .inner {
      position: relative;
      text-align: center;
      text-shadow: 0 0.063em 0.063em*2 rgba(0, 0, 0, .5);
      color: $white;

      h5, .h5 {
        font-size: 1.5rem;
        color: white;
      }
    }

    .inner-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      height: 100%;
      background: rgba(0, 0, 0, .5);
      border-radius: 12px;
      overflow: hidden;
    }

    .inner,
    .inner-wrapper {
      @include transition();
    }
  }

  .extrainfo {
    padding: $space * 0.5;

    a {
      line-height: $space;
    }
  }

  li figure:hover {
    .inner-wrapper {
      opacity: 0;
    }
  }
}

.small {
  font-size: 0.8rem;
}

.owl-nav {
  position: absolute;
  top: -2.25rem;
  right: 9px;
  left: 9px;
  font-size: .875rem;
  text-align: right;
  @extend .px-3;
}

.owl-carousel {
  padding: 0 9px 0 9px;
}

.calendar-item {
  border: solid 2px $primary;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 12px;
}

.double-spaced {
  height: 58px;
}

.bottom-layer {
  object-fit: cover;
}

.footer-overlap {
  margin-bottom: -8rem;
  padding-bottom: 3rem;
}

.search-btn {
  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-top: 1rem;
  }
}

.intrest-badge {
  height: 4.5rem;
  width: 4.5rem;
  white-space: pre-wrap;
  right: 1rem;
  top: -4rem;

  p {
    vertical-align: middle;
    font-size: 0.6rem;
  }
}

.shortlist-item-flavor {
  bottom: -1px;
  left: 0;
  right: 0;
}

.card-body {
  .media-body {
    form {
      display: inline !important;

      button {
        padding-top: 0;
        padding-bottom: 0;
        height: 30px;
        margin-top: .5rem;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .btn-group .dropdown-menu {
    top: auto !important;;
    margin-top: 61px !important;
  }
}

.account--button {

}
